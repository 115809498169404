h1 {
    font-size: 42px;
    color: #2c3e50;
}

input[type="checkbox"] {
    position: relative;
    width: 40px;
    height: 20px;
    background: #bdc3c7;
    -webkit-appearance: none;
    border-radius: 20px;
    outline: none;
    transition: 0.4s;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

input:checked[type="checkbox"] {
    background: #70ed69;
}

input[type="checkbox"]::before {
    z-index: 1;
    position: absolute;
    content: "";
    left: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
}

input:checked[type="checkbox"]::before {
    left: 20px;
}

.toggle {
    position: relative;
    display: inline;
    z-index: 1;
}

.toggle .onbtn {
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    pointer-events: none;
    top: -13px;
    left: 5px;
}

.toggle .ofbtn {
    position: absolute;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    pointer-events: none;
    top: -13px;
    right: 5px;
}
