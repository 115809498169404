:root {
    --primary--: #00bb20;
    --secondary--: #b8dc01;
    --dark-light--: #f2f7f9;
}
.cursor-pointer {
    cursor: pointer;
}
.modal-dialog {
    position: relative;
    max-width: 100% !important;
    height: -webkit-fill-available;
    margin: 0px !important;
    z-index: 10;
}
.modal-content {
    height: -webkit-fill-available;
    border: none !important;
    border-radius: 0 !important;
}

.modal:has(.contact-modal) {
    width: calc(100% - 600px);
    height: calc(100% - 96px);
    margin: 48px 300px;
    border-radius: 3px;

    @media (max-width: 1200px) {
        width: calc(100% - 48px);
        height: calc(100% - 48px);
        margin: 24px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        margin: 12px;
    }

    // .contact-modal {
    //     height: auto;
    // }

    .modal-footer,
    .modal-body,
    .modal-header
    {
        padding: 12px 24px;
    }

    .modal-content {
        border-radius: 3px !important;
    }
}

.modal:has(.update-payment-modal) {
    height: auto;
}

.btn-close {
    background-image: url("../img/close-dark.png");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    height: 16px;
    width: 16px;
    border: none;
    margin-top: 12px;
}

.form-cs-style {
    cursor: pointer;
}
.form-cs-style:hover,
.form-cs-style:active,
.form-cs-style:focus {
    text-decoration: underline;
}
.fcs-style {
    font-size: 4rem !important;
    border: none !important;
    border-bottom: 2px solid var(--primary--) !important;
}
.fcs-style:hover,
.fcs-style:active,
.fcs-style:focus {
    box-shadow: none !important;
}
.fw-medium {
    font-weight: 600;
}
.fs-4 {
    font-size: 18px !important;
}
.fsw-4 {
    font-size: 16px !important;
    font-weight: 500;
}
.form-custom-style {
    border: 1px solid var(--primary--) !important;
}
.form-custom-style:hover,
.form-custom-style:active,
.form-custom-style:focus {
    border: 1px solid var(--secondary--) !important;
    box-shadow: 0 0 3px 1px var(--secondary--) !important;
}
.custom-a-hover {
    color: var(--primary--) !important;
}
.custom-a-hover:hover,
.custom-a-hover:active,
.custom-a-hover:focus {
    text-decoration: underline;
    text-decoration-color: var(--primary--) !important;
    color: var(--primary--) !important;
}
.bd-right {
    border-right: 1px solid var(--primary--) !important;
}
.bd-tabs.active {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid var(--primary--) !important;
    box-shadow: none !important;
}
.text-bearish {
    background-color: #e6f0f5 !important;
}
.text-bearish td > p {
    font-weight: bold !important;
}
.custom-index {
    z-index: 0 !important;
}
.bg_holes_profile {
    background-color: #121a20;
}
.bg-dark-light {
    background-color: var(--dark-light--) !important;
}
.text-light-green {
    color: rgba(255, 255, 255, 0.7) !important;
}
.bg-full-light {
    background-color: rgb(248 249 250 / 7%) !important;
}
.custom-border {
    border-radius: 12px 12px 12px 12px !important;
}
.link-hover > a:hover,
.link-hover > a:active,
.link-hover > a:focus {
    border-bottom: none !important;
    text-decoration: none !important;
}
.custom-zoom-style {
    // position: absolute !important;
    // top: 15px !important;
    // right: 15px !important;
    // transform: translate(0%) !important;
    width: 60px !important;
    // padding: 10px;
}

@media (max-width: 768px) {
    .fcs-style {
        font-size: 2rem !important;
    }
    .fsw-4 {
        font-size: 14px !important;
    }
    .custom-padding {
        padding: 10.5px 0 11px 0 !important;
    }
}
// @media (max-width: 540px) {
//     .custom-zoom-style {
//         left: 2% !important;
//         width: 160px !important;
//     }
// }

.bg_holes_profile .w-25 {
    border-right: 1px dotted rgba(255,255,255,0.2);
    background-color: rgba(18,26,32,0.9);
    max-height: calc(100% - 30px);
}
