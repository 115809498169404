.documentation {
    @media (max-width: 991.98px) {
        // overflow: hidden;
        // height: 100vh;
    }
}
.documentation-sidebar {
    border-right: 1px solid #eee;
    background-color: #f6f6f6;
    // @media (min-width: 1230px) {
    //     top: 20% !important;
    // }
    top: 80px;
    height: 100vh;
    overflow: auto;
    position: sticky;
    bottom: 0;
    // width: 250px;
    padding: 1.5rem 1rem 2rem 1.5rem;
    // padding-top: 200px;
    padding-bottom: 200px !important;
    z-index: 999;
    @media (min-width: 992px) and (max-width: 1199.98px) {
        top: 80px;
    }
    @media (max-width: 991.98px) {
        top: 70px;
    }
    @media (max-width: 593.98px) {
        width: 100%;
        position: fixed;
    }
}

.documentation-sidebar a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    cursor: pointer;
}

.documentation-sidebar .active {
    color: #00bb20 !important;
}

.section {
    margin-bottom: 50px; /* to make scrolling noticeable */
    @media (max-width: 991.98px) {
        margin-bottom: 20px;
    }
}

.section:first-of-type {
    margin-bottom: 50px;
    @media (max-width: 593.98px) {
        margin-bottom: 0;
    }
}

.section-guides {
    margin-bottom: 50px;
    @media (max-width: 991.98px) {
        margin-bottom: 20px;
    }
}
.toggleIcon {
    position: fixed;
    right: 20px;
    top: 180px;
    z-index: 9999;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.342);
    border-radius: 5px;
    line-height: 0;
    margin-top: 5px;
    @media (min-width: 992px) and (max-width: 1199.98px) {
        top: 80px;
    }
    @media (max-width: 991.98px) {
        top: 80px;
    }
}
.header {
    background-color: #f7f7f7;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

    // cursor: pointer;
    position: fixed;
    z-index: 100;
    width: 100%;
}

.docSideBar-min {
    position: absolute;
    top: 70px;
}
.header.expanded {
    .side-items {
        margin: auto;
        width: 98%;
    }
    // position: fixed;
    // right: 25%;
    // padding-right: 200px;
}
.main-content {
    // position: relative;
    // left: 250px;
    width: calc(100vh - 250px);
}
// .section-code {
//     padding: 12px 12px 200px 12px; /* to make scrolling noticeable */
//     @media (max-width: 991.98px) {
//         padding: 12px;
//     }
// }
.path {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e2e2e2;
    background-color: #f6f6f6;
    padding: 0.75rem;
}
.path-title {
    color: #f68b1f;
    font-size: 0.9rem;
    margin: 1em 0;
}

h6 {
    margin-top: 30px;
}

.code-tip {
    line-height: 1.25;
    padding: 1.5rem 2rem;
    border-radius: 5px;
    box-shadow: 0 0 200px rgba(0, 0, 0, .33) inset;
    margin: 0 12px 24px 12px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #404040;
    white-space: pre-wrap;
    word-break: normal;
    word-spacing: normal;
}

.code-tip > span {
    background-color: transparent !important;
}

.documentation .bg-site-bgDark {
    flex: 1;
    padding-bottom: 150px;
    @media (max-width: 593.98px) {
        padding: 15px;
    }
}

.documentation .col > div:nth-of-type(2),
.documentation .col > div:nth-of-type(15) div div div:nth-of-type(2) {
    display: none !important;
}

.documentation .col > div:first-of-type .section {
    padding-bottom: 0;
}

.documentation .col .section {
    padding: 0 24px 150px 24px;
    @media (max-width: 593.98px) {
        padding: 0 24px 24px 24px;
    }
}

.documentation .col > div {
    @media (max-width: 593.98px) {
        padding-bottom: 96px;
    }
}

.bg-danger {
    color: #fff;
}
