@import "./signin.scss";
@import "./api-navbar.scss";
@import "./pages.scss";
@import "./signin.scss";
@import "./example.scss";
@import "./tutorial.scss";
@import "./documentations.scss";
@import "./NavbarSearchModal.scss";
@import "./toggle-Button.scss";

@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=italic:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
$body-bg: #f3f4ff;
$body-color: #050516;

$theme-colors: (
    "site-primary": #2eba26,
    "site-bgDark": #121a20,
    "site-lite-btn": #f2f7f9,
    "site-secondary": #f3f4ff,
    "site-secondary1": #d1d5e0,
    "site-muted": #8fa2bb,
    "text-mute": #6e6e7d,
    "site-footer-item": #d2d7da,
    "site-border-color": #e3e4e6,
    "site-nav-link": #060a0d,
    "site-lite-green": #eff9ec,
    "site-bgGreen": #70ed69,
    "site-gray": #757575,
    "table-row": #f7f8f9,
    "site-map": #283032,
);
@import "node_modules/bootstrap/scss/bootstrap";

// ::-webkit-scrollbar {
//     width: 0.23rem;
//     height: 0.313rem;
// }
// ::-webkit-scrollbar-track {
//     background: theme-color("site-secondary1");
// }
// ::-webkit-scrollbar-thumb {
//     background-color: theme-color("site-muted"); /* color of the scroll thumb */
//     border-radius: 1.25rem; /* roundness of the scroll thumb */
// }

.form-label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}

// Override Stripe styles
.StripeElement {
    background-color: #ff0000;
    padding: 0.8rem 0.75rem;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(247, 247, 247);
}

.StripeElement:hover {
    border: 1px solid #d4ff00 !important;
    box-shadow: 0px 1px 3px rgba(143, 255, 54, 0.4) !important;
}

@media (max-width: 575px) {
    .subscribe-form-button {
        width: 100%;
    }

}

.font-archivo {
    font-family: "Archivo", sans-serif;
}
.font-poppins {
    font-family: "Poppins", sans-serif;
}
.font-italic {
    font-family: "italic", sans-serif;
}
.font-code {
    font-family: 'Space Mono', monospace;
}
@for $size from 5 through 96 {
    .fs--#{$size} {
        font-size: $size/16 + rem;
    }
}

@for $i from 100 through 900 {
    .fw--#{$i} {
        font-weight: $i;
    }
}

.extra-pb-y {
    padding-bottom: 8rem !important;
}

code {
    font-family: 'Space Mono', monospace !important;
    font-size: 12px !important;
}

.example-code__code-block > span {
    background-color: #1f272c !important;
}

.bullet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        margin: 20px 0;
        padding-left: 30px;
        background-image: url("../img/checkmark.png");
        background-repeat: no-repeat;
        background-position: 0 6px;
        background-size: 12px 12px;
    }
}

.cancelMembership__btn-member__btn {
    width: 250px;
}

.cancelMembership__btn-member__btn.btn-site-lite-btn {
    color: #6e6e7d;
}

.landing-footer-action {
    @media (max-width: 900px) {
        text-align: center;
        padding: 0 !important;
    }
}

.px-wide {
    padding-left: 96px !important;
    padding-right: 96px !important;
    @media (max-width: 768px) {
        padding-left: 48px !important;
        padding-right: 48px !important;
    }
    @media (max-width: 575px) {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}

.px-lg-narrow {
    @media (min-width: 1200px) {
        padding-left: 192px !important;
        padding-right: 192px !important;
    }
}

.header__bg span, .header__bg button.btn-site-bgGreen {
    margin-top: 50px;
    display: inline-block;
} 

header.vh-100 {
    height: auto !important;
    margin-top: 0 !important;
}

header.vh-100 .vh-100 {
    height: auto !important;
    padding-top: 120px;
    padding-bottom: 120px;
    @media (max-width: 1200px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

header.vh-100 .vh-100 h1 {
    @media (max-width: 900px) {
        font-size: 2rem;
    }
}

a, a:hover {
    color: theme-color(site-primary);
}
.header {
    // background-color: white;
    // h1 {
    //     font-size: 2.5rem;
    //     font-weight: 700;
    //     @media (max-width: 1150px) {
    //         font-size: 2rem;
    //     }
    //     @media (max-width: 990px) {
    //         font-size: 1.9rem;
    //     }
    //     @media (max-width: 768px) {
    //         font-size: 1.55rem;
    //     }
    //     @media (max-width: 500px) {
    //         font-size: 1.4rem;
    //     }
    //     @media (max-width: 400px) {
    //         font-size: 1.2rem;
    //     }
    // }
    &__bg {
        background-image: url("../img/background.png");
        background-color: theme-color("site-bgDark");
        // margin-left: 4rem;
        // min-height: 100vh;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        &-image {
            background-image: url("../img/main.png");
            background-position-x: right;
            background-position-y: bottom;
            background-repeat: no-repeat;
            background-size: contain;
            @media (max-width: 767.98px) {
                background-image: none;
            }
        }
    }

    .f-row {
        z-index: 10;
        // margin-top: -5rem;
        @media (max-width: 990px) {
            margin-top: 0;
            padding: 50px 0;
        }
        @media (max-width: 768px) {
            padding: 50px 0;
        }
        @media (max-width: 575px) {
            margin-left: 0;
            padding: 0px 0;
        }
    }
    .f-coll {
        margin-top: auto;
        .has-search {
            background-color: #1d252c;
            border: 1px solid theme-color(site-bgGreen);

            .form-control {
                background-color: inherit;
                height: calc(1.5em + 0.75rem + 10px);
                border: none;
                box-shadow: none;
                &:focus {
                    // box-shadow: 0px 1px 3px rgba(143, 255, 54, 0.4);
                    // border: #d4ff00 1px solid !important;
                }
            }
        }

        // .has-search .form-control-feedback {
        // }
    }
    .f-colr {
        padding: 0;
        padding-top: 35px;
        img {
            padding-left: 270px;
            width: 100%;
            @media (max-width: 1450px) {
                padding-left: 200px;
            }
            @media (max-width: 1300px) {
                padding-left: 130px;
            }
            @media (max-width: 1050px) {
                padding-left: 80px;
            }
            @media (max-width: 768px) {
                padding: 0;
            }
            @media (max-width: 575px) {
                padding: 0;
                width: 80%;
                // margin: auto !important;
                margin-left: 70px;
                text-align: center;
            }
        }
    }
    .header-btn {
        width: 21%;
        margin-right: 20px;

        @media (max-width: 1650px) {
            width: 21%;
        }
        @media (max-width: 1450px) {
            width: 24%;
        }
        @media (max-width: 1300px) {
            width: 26%;
        }
        @media (max-width: 1150px) {
            width: 24%;
        }
        @media (max-width: 1050px) {
            width: 26%;
        }
        img {
            width: 100%;
        }
    }
    .wave-section-separator {
        z-index: 0;
        .wave {
            fill: theme-color(site-bgGreen);
        }
    }
}
.navbar-style {
    // font-family: "Nunito", sans-serif;
    padding-left: 3%;
    padding-right: 3%;
    position: fixed;
    margin-top: -75px;
    width: 100%;
    height: 75px;
    overflow: visible;
    background-color: rgba(19, 26, 32, 0.88);
    z-index: 2;
    @media (max-width: 991.98px) {
        border: 0;
        padding-left: 1.5%;
        padding-right: 1.5%;
        box-shadow: none;
        position: fixed;
        width: -webkit-fill-available;
        // height: 70px;
    }
    .navbar-header {
        width: 12%;
        @media (max-width: 1200px) {
            // width: 10%;
            width: auto;
        }
        @media (max-width: 575px) {
            // z-index: 200;
            // width: 15%;
            width: auto;
        }
        .logo-text {
            width: 150px;
            @media (max-width: 1320px) {
                width: 140px;
            }
            @media (max-width: 1200px) and (min-width: 991px) {
                width: 130px;
            }
        }
    }
    .navbar-collapse {
        @media (max-width: 990px) {
            // margin-top: -100px;
            // background-color: #ffffff26;
        }
    }
    .navbar-nav {
        @media (max-width: 990px) {
            // margin-top: 90px;
        }
        @media (max-width: 400px) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            margin: 0;
            height: 100vh;
            z-index: 10;
        }
    }
    .nav-item {
        margin: auto;
        display: flex;
        align-items: center;
        .navlink {
            margin: 10px 0;
            color: $body-color;
            font-size: 14px;
            // text-wrap: nowrap;
            // font-weight: 500;
            cursor: pointer;
            color: white;
            &:hover {
                text-decoration: none;
                color: theme-color("site-bgGreen");
            }
            &:active {
                border-bottom: 2px solid theme-color("site-primary") !important;
            }
            @media (max-width: 1320px) {
                margin: 10px 15px;
            }
            @media (max-width: 1180px) {
                font-size: 1rem;
                margin: 10px 13px;
            }
            @media (max-width: 1045px) {
                margin: 10px 10px;
            }
            // &:active {
            //     // color: theme-color("site-bgGreen");
            //     // font-weight: 600;
            //     text-decoration: none !important;
            //     border-bottom: 2px solid theme-color("site-bgGreen") !important;
            // }
        }
    }
    .navlink-active {
        // color: theme-color("site-bgGreen");
        // font-weight: 600;

        text-decoration: none !important;
        border-bottom: 2px solid theme-color("site-bgGreen") !important;
    }
    .nav-btn {
        border-radius: 0px;
        font-size: 14x;
        padding: 5px 20px;
        margin: 0px 10px;
        text-wrap: nowrap;
    }
}
.children {
    margin-top: 75px;
}
.sidebarToggle {
    width: 250px;
    margin-top: -8px;
    margin-left: -30px;
    // padding-top: 60px;
    z-index: 200;
    overflow-y: auto;
    position: absolute;

    @media (max-width: 990px) {
        margin-left: -15px;
    }
    .nav-item {
        margin: 0px auto;
        justify-content: center;
        @media (max-width: 990px) {
            margin: 2px auto;
        }
        .navlink {
            font-weight: 400;
            font-size: 14px;
            &:hover {
                color: theme-color(site-bgGreen);
            }
            &:active {
                text-decoration: none !important;
                border-bottom: 2px solid #00bb20 !important;
            }
        }
    }
}
.sidebarToggle .btn {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid rgba(255,255,255,0.1) !important;
    font-size: 14px;
}
.sidebarToggle .btn:hover {
    color: theme-color(site-bgGreen);
}
.navbar-toggler {
    z-index: 200;
}
.navbar-toggler-icon {
    // background-image: url("../img/Landing\ page-slicing/download.svg");
    background-position: top;
    background-repeat: no-repeat;
    z-index: 200;
    width: 0;
    height: 0;
    cursor: pointer;
}
.active {
    .navbar-toggler-icon {
        // background-image: url("../img/close.svg") !important;
        display: none !important;
        background-position: top;
        z-index: 200;
        background-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 170px;
        margin-top: -45px;
    }
}
.fa-times {
    z-index: 200;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 12px;
    left: 12px;
}
.collapse-setting {
    position: absolute !important;
    top: 10px;
}
.page-footer {
    // background-color: white;
    z-index: 10;

    .navbar-header {
        img {
            width: 50%;
            display: flex;
            margin: auto;
        }
        @media (max-width: 670px) {
            margin: auto;
            text-align: center;
        }
    }
    .footer-nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        // margin-left: -250px;
        @media (max-width: 990px) {
            justify-content: center;
            margin-left: 0;
        }
        @media (max-width: 670px) {
            justify-content: center;
            // margin-right: 35px;
        }
        .footer-btn {
            width: 30%;
            cursor: pointer;
            margin: 10px;
            img {
                width: 100%;
            }
        }
    }
    .footer-social {
        margin-left: auto !important;
        justify-content: end;
        display: flex;
        margin: auto;
        @media (max-width: 990px) {
            // margin: auto;
            justify-content: center;
        }
        .social-btn {
            width: 12%;
            @media (max-width: 1450px) {
                width: 100%;
            }
            // margin-right: 20px;
            img {
                width: 100%;
            }
        }
    }
    .footer-navitems {
        display: flex;
        padding-bottom: 50px;
        .navbar-nav {
            flex-direction: row !important;
            .navlink {
                padding: 10px;
                color: $body-color;
                font-size: 1rem;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                    color: theme-color("site-primary");
                }
                @media (max-width: 400px) {
                    font-size: 0.8rem;
                    padding: 5px;
                }
            }
            .nav-btn {
                border-radius: 50px;
                font-size: 1.1rem;
                font-weight: 600;
                padding: 5px 20px;
                margin: 0px 10px;
            }
        }
    }
    .contct {
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 50px;
        @media (max-width: 670px) {
            display: grid;
            // margin-top: 20px;
        }
        a {
            color: $body-color;
            text-decoration: none;
            margin-top: 10px;
            img {
                // width: 8%;
                margin-top: -5px;
                margin-right: 10px;
                margin-left: 50px;
                @media (max-width: 870px) {
                    margin-right: 5px;
                    margin-left: 0;
                }
            }
        }
    }
    .wave-section-separator {
        z-index: -1 !important;
        .wave {
            fill: #d8daea;
        }
    }
}
.btn-custom {
    background-color: theme-color("site-lite-btn");
    //background: transparent;
    padding: 18px 36px;
    color: theme-color("site-primary");
    font-size: 18px;
    font-weight: 500;
    border: none;
    &:hover {
        background-color: theme-color("site-lite-btn");

        color: theme-color("site-primary");

        border: none;
    }
    &:active {
        background-color: theme-color("site-lite-btn");

        color: theme-color("site-primary");

        border: none;
    }
}
.cursor-pointer {
    cursor: pointer;
}

// .d-flex,
// .row {
//     padding: 1.5rem 0.5rem;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
// }

.tooltip-inner {
    // max-width: 500px !important;

    //align-items: center;
    color: #ffffff;
    font-size: "24px" !important;
    font-weight: 600 !important;
    padding: 10px;
    border-radius: 12px !important;
}

.sample-code {
    &__card {
        border-radius: 8px;
        @media screen and (max-width: 776.98px) {
            margin-bottom: 36px !important;
        }
    }
    &__img {
        height: 120px;
        width: 120px;
        @media screen and (max-width: 776.98px) {
            height: 80px;
            width: 80px;
        }
    }
}

.landing-map-item {
    @media screen and (max-width: 776.98px) {
        margin-bottom: 48px !important;
    }
}

// .api-pages {
//     padding-top: 80px !important;
//     @media (max-width: 991.98px) {
//         padding-top: 70px !important;
//     }
// }
.navlink-wrap {
    white-space: normal !important;
    word-break: break-word;
}

.Toastify__toast-container--top-right {
    top: 7em !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;

    font-size: 1rem;
    user-select: none;
}

/* Create a custom checkbox */
.checkmark {
    --clr: #009519;
    position: relative;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    border-radius: 0.2rem;
    background-color: transparent;
    transition: 300ms;
    border: 1.5px solid #009519;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: var(--clr);
    border-radius: 0.2rem;
    animation: pulse 500ms ease-in-out;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 0.3em;
    top: 0.15em;
    width: 0.25em;
    height: 0.5em;
    border: solid #ffffff;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
}

.map_source_thumb {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 12px;
}

.index_btns {
    height: 36px;
    width: 36px;
    margin: 4px;
    border-radius: 2px;
    background-color: #f8f9fa;
    border: transparent;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        border: 1px solid #00bb20 !important;
        color: #00bb20;
    }

}

.index_btns-first {
    width: auto;
}

.App-map {
    height: 100%;
    width: 100%;
}
.sticky-column {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: #fff; /* Optional: Add a background color for better visibility */
    z-index: 1; /* Ensure the sticky column stays above other elements */
}
.height-small {
    // @media screen and (max-width: 776.98px) {
    //     height: 200px !important;
    // }
}
// .select-styling {
//     border-color: #00bb20 !important;
//     box-shadow: 0 0 0 1px transparent !important;
// }
// .css-t3ipsp-control {
//     border-color: #00bb20 !important;
//     box-shadow: #00bb20 !important;
// }
// .css-13cymwt-control {
//     border-color: #00bb20 !important;

//     box-shadow: none !important;
// }

.align-items-right {
    text-align: right;
}

.course-info {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin: 1px 0;
        padding: 0;
    }
}

.align-items-bottom {
    align-self: center;
}

.course-tabs {
    width: 100%;
    display: block;
    text-align: center;

    @media screen and (max-width: 776.98px) {
        margin-top: 24px;
    }
}

.bg-table-row {
    background-color: theme-color(table-row);
}

@media (min-width: 992px) {
    .scorecard-table-flex.col-lg-3 {
        flex: 0 0 15%;
        max-width: 15%;
    }

    .scorecard-table-flex.col-lg-9 {
        flex: 0 0 85%;
        max-width: 85%;
    }
}






// GOLF COURSE HOLE

.hole-detail {
    // background-color: theme-color(site-bgDark);
}

BODY:has(.hole-detail) .navbar {
    // background-color: theme-color(site-bgDark);
}

BODY:has(.hole-detail) .card {
    background-color: transparent;
    color: theme-color(site-muted);
    border: none;
    @media (max-width: 776.98px) {
        padding: 0 15px !important;
    }
}

BODY:has(.hole-detail) .custom-a-hover,
BODY:has(.hole-detail) .card a {
    color: theme-color(site-primary) !important;
}

BODY:has(.hole-detail) .card .course-name {
    display: block;
    width: 100%;
    font-weight: 600;
}

.hole-detail-teebox {
    width: 100%;
}

BODY:has(.hole-detail) .index_btns {
    background-color: theme-color(site-secondary) !important;
    color: theme-color(site-bgDark);
}

BODY:has(.hole-detail) .index_btns.text-site-primary {
    border-color: theme-color(site-bgGreen) !important;
    color: theme-color(site-primary) !important;
}

BODY:has(.hole-detail) .index_btns.index_btns-first {
    border-color: transparent !important;
    a {
        color: theme-color(site-muted);
    }
}

BODY:has(.hole-detail) .index_btns.index_btns-first:hover {
    border-color: theme-color(site-bgGreen) !important;
}

.index_btns.index_btns-first a:hover {
    text-decoration: none;
}

BODY:has(.hole-detail) .footnote {
    color: theme-color(site-muted);
}

BODY:has(.hole-detail) .App-map-container {
   border-top: 1px solid rgba(255,255,255,0.1);
   border-bottom: 1px solid rgba(255,255,255,0.1);
}

.rounded-pill {
    border-radius: 3px !important;
}

.hole-detail-select {
    width: 60px;
}

@media (max-width: 992px) {
    .hole-detail-pagination {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .App {
        height: 180px !important;
    }

    .hole-detail .App {
        height: 320px !important;
    }
}

.bg_holes_profile .w-25 {
    border-right: none !important;
    background-color: transparent !important;
}






// FAQ

.faq__head-question:hover {
    cursor: pointer;
}

// TABLES

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.015);
}

// CODE EXAMPLES

.example-code .example-code__code-block > span {
    padding: 1rem !important;
}

.auth-header  {
    padding-top: 75px !important;
}

.auth-header .navbar  {
    padding: 0 !important;
}