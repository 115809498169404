.tutorial,
.guide {

    .sidebar {
        max-width: 100%;
        background-color: #fff;
        padding: 15px;
        height: calc(100vh - 160px);

        @media (min-width: 1200px) {
            position: sticky;
            top: 100px;
        }
        @media (min-width: 992px) and (max-width: 1199.98px) {
            position: sticky;
            top: 80px;
            height: calc(100vh - 80px);
        }
        z-index: 1;
    }

    .sidebar a {
        display: block;
        margin-bottom: 10px;
        color: black;
        text-decoration: none;
    }

    .sidebar .active {
        color: #2eba26 !important;
    }

    .section {
        margin-bottom: 200px; /* to make scrolling noticeable */
    }

    &__header {
        background-color: #f7f7f7;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: sticky;
        top: 70px;
        z-index: 1;
        width: 100%;
    }

    .header.expanded {
        .side-items {
            margin: auto;
            width: 98%;
        }
    }

    h2 {
        margin-top: 50px !important;
    }
}

div.bg-site-bgDark {
    background-color: rgb(40, 42, 54) !important;
}

code {
    padding: 0 !important;
}

.tutorial .w-100 > span {
    padding: 1rem !important;
}

.container-lg .w-100 {
    margin-right: 0;
    margin-left: 0;

    .col-lg-9 {
        padding-right: 0;
        padding-left: 0;
    }
}

.tutorial h2.guides-intro-header {
    margin-top: 0 !important;
}