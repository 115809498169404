@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Montserrat:wght@300;400;500;600;700&display=swap");

body {
    background-color: #fff !important;
}

.bg-membership {
    background-color: #f2f7f9;
}

.mail-icon {
    color: #454d52 !important;
}

.link-to {
    text-decoration: none;
}

.terms {
    padding-top: 15px;
    padding-bottom: 150px;
    font-family: "Poppins", sans-serif;

    &__headline {
        font-weight: 700 !important;
        font-size: 2.4em;
        letter-spacing: -0.8px;
        line-height: 1em;
    }

    &__graytext {
        color: #757575;
    }

    &__lgtext {
        font-size: 18px;
        line-height: 20px;
    }
}

//Subscribe
.sub-details {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        padding: 0;
        margin: 0;
    }
    li:first-of-type {
        border-bottom: 1px solid #ddd;
        padding-bottom: 12px;
        margin-bottom: 12px;
    }
}

//Privacy Page
.privacy {
    padding-top: 15px;
    padding-bottom: 150px;
    font-family: "Poppins", sans-serif;

    &__headline {
        font-weight: 700 !important;
        font-size: 2.4em;
        letter-spacing: -0.8px;
        line-height: 1em;
    }

    &__lgtext {
        font-size: 18px;
        line-height: 20px;
    }
}

//License
.license {
    padding: 20px !important;
    padding: 15px;
    padding-top: 15px;
    padding-bottom: 150px;
    font-family: "Poppins", sans-serif;
    &__headline {
        font-weight: 700 !important;
        font-size: 2.4em;
        letter-spacing: -0.8px;
        line-height: 1em;
    }

    &__lgtext {
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
    }
}

//FAQS
.faq {
    font-family: "Poppins", sans-serif;
    background: #fff;

    h1 {
        font-weight: 700;
        font-size: 1.85em;
        padding: 30px 0 3px 0;
    }

    p {
        font-size: 1rem;
        letter-spacing: -0.3px;
        margin-bottom: 30px;
    }

    &__head-question {
        padding: 20px 0;
        line-height: 0.9;
        margin: 0;
        border-top: 1px solid #eee;
        font-weight: 400;
        font-size: 18px;

        @media (max-width: 720px) {
            line-height: 1.4;
            font-size: 16px;
        }
    }

    &__link-to {
        color: #00bb20 !important;
        text-decoration: none;
        padding: 4px;
    }

    // &__ques-active {
    //     color: rgb(0, 187, 32);
    // }
}

//Support
.support {
    font-family: "Poppins", sans-serif;
    background: #fff;
    padding-bottom: 100px;

    h1 {
        font-weight: 600;
        font-size: 1.85em;
        width: 100%;
        padding: 30px 0 3px 0;
    }

    h4 {
        margin: 30px 0 15px;
        font-weight: 600;
        font-size: 1.2em;
    }

    &__head-text {
        font-size: 1rem;
        margin-bottom: 30px;
    }

    &__para-text {
        font-size: 1rem;
        margin-bottom: 48px;
    }

    &__link-to {
        text-decoration: none;
        padding: 4px;
        float: right;
    }

    &__link-tofAQ {
        text-decoration: none;
    }

    &__support-button {
        padding: 10px 14px 10px 14px !important;
    }

    &__side {
        @media (max-width: 992.9px) {
            display: none;
        }
    }
}

//Timeline
.timeline {
    font-family: "Poppins", sans-serif;
    background: #fff;
    padding-bottom: 160px !important;

    &__first-sec {
        background: #f2f6f9;
        padding-top: 2em;

        h3 {
            font-size: 24.5px;
            font-weight: 700 !important;
            padding: 10px;
        }

        p {
            font-size: 1rem !important;
            letter-spacing: -0.3px !important;
            margin-top: 30px !important;
            margin-bottom: 30px !important;
        }
    }
}

.design-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    // min-height: 10vh;
    padding: 20px 0;
    font-family: "Poppins", sans-serif;

    &__timeline-link {
        text-decoration: none;
        margin-right: 2px;
        margin-left: 2px;
    }

    &__mid-year {
        font-weight: 500 !important;
        font-size: 14px !important;
        padding: 10px 0px;
    }

    &__timeline-sec {
        height: auto;
        max-width: 200%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end !important;
    }
}

.timeline-content {
    font-weight: 400 !important;
    font-size: 14px !important;
    transition: 0.4s ease;
    overflow-wrap: normal !important;
    //word-break: break-all !important;
    margin: 1rem;
    @media (max-width: 500px) {
        font-weight: 400 !important;
        font-size: 12px !important;
    }
}

// .timeline-component,
// .timeline-empty {
//     margin: 0px 20px 20px 20px;
//     line-height: 0.6;
//     width: calc(100% - 42px);
// }

.timeline-component,
.timeline-empty {
    margin: -5px 20px 20px 20px;
    p {
        line-height: 1.25rem;
        padding: 0;
        margin: 0;
    }
    @media (max-width: 419.99px) {
        margin: -5px 0 20px 0;
        p {
            padding: 0 12px;
        }
    }
}

@media screen and (min-width: 768px) {
    .design-section {
        &__timeline-sec {
            display: grid;
            grid-template-columns: 1fr 3px 1fr;
        }
    }

    .timeline-middle {
        position: relative;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        width: 2px;
        height: 100%;
    }

    .main-middle {
        opacity: 0;
        z-index: 1;
    }

    .timeline-circle {
        position: absolute;
        top: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

@media (max-width: 768px) and (min-width: 466px) {
    .design-section {
        &__timeline-sec {
            display: grid;
            grid-template-columns: 1fr 2px 1fr;
        }
    }

    .timeline-content {
        font-weight: 400 !important;
        font-size: 14px !important;
        transition: 0.4s ease;
        margin: 1rem;
        line-height: 1 !important;
    }

    .timeline-component {
        margin: 0px 20px 20px 20px;
        line-height: 0.8;
    }

    .timeline-middle {
        position: relative;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        width: 2px;
        height: 100%;
    }

    .main-middle {
        opacity: 0;
        z-index: 1;
    }

    .design {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeline-circle {
        position: absolute;
        top: 0;
        left: 55%;
        width: 8px;
        height: 8px;
        border-radius: 55%;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .design-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        // min-height: 10vh;
        padding: 20px 0;
        font-family: "Poppins", sans-serif;

        &__timeline-link {
            color: #00bb20 !important;
            text-decoration: none;
            margin-right: 2px;
            margin-left: 2px;
        }

        &__mid-year {
            font-weight: 500 !important;
            font-size: 14px !important;
            padding: 10px 0px;
        }
    }
}

@media (max-width: 465.99px) and (min-width: 420px) {
    .design-section {
        &__timeline-sec {
            display: grid;
            grid-template-columns: 1fr 1px 1fr;
        }
    }

    .timeline-content {
        font-weight: 400 !important;
        font-size: 14px !important;
        transition: 0.4s ease;
        margin: 1rem;
        line-height: 1 !important;
    }

    .timeline-component {
        margin: -5px 20px 20px 20px;
        line-height: 0.8;
    }

    .timeline-middle {
        position: relative;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        width: 2px;
        height: 100%;
    }

    .main-middle {
        opacity: 0;
        z-index: 1;
    }

    .design {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeline-circle {
        position: absolute;
        top: 0;
        left: 55%;
        width: 8px;
        height: 8px;
        border-radius: 55%;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .design-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        // min-height: 10vh;
        padding: 20px 0;
        font-family: "Poppins", sans-serif;

        &__timeline-link {
            text-decoration: none;
            margin-right: 2px;
            margin-left: 2px;
        }

        &__mid-year {
            font-weight: 500 !important;
            font-size: 14px !important;
            padding: 10px 0px;
        }
    }
}

@media (max-width: 419.99px) and (min-width: 300px) {
    .design-section {
        &__timeline-sec {
            display: grid;
            grid-template-columns: 1fr 1px 1fr;
        }
    }

    .timeline-content {
        font-weight: 400 !important;
        font-size: 14px !important;
        transition: 0.4s ease;
        //margin: 1rem;
        line-height: 1 !important;
    }

    .timeline-component p.text-right {
        text-align: right;
    }

    .timeline-middle {
        position: relative;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        width: 2px;
        height: 100%;
    }

    .main-middle {
        opacity: 0;
        z-index: 1;
    }

    .design {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeline-circle {
        position: absolute;
        top: 0;
        left: 55%;
        width: 8px;
        height: 8px;
        border-radius: 55%;
        background-image: linear-gradient(45deg, #00bb20, #00bb20, #00bb20);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .design-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 20px 0;
        font-family: "Poppins", sans-serif;

        &__timeline-link {
            text-decoration: none;
            margin-right: 2px;
            margin-left: 2px;
        }

        &__mid-year {
            font-weight: 500 !important;
            font-size: 14px !important;
            padding: 10px 0px;
        }
    }
}

//Setting
.setting {
    background-color: #fff !important;
    background-size: cover;
    font-family: "Poppins", sans-serif;
    font-size: 0.9em;
    margin-bottom: 80px;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        margin: 3px 0;
    }

    &__heading {
        font-weight: 600;
        font-size: 1em;
        width: 100%;
        padding: 30px 0 3px 0;
    }

    &__para-strong {
        font-weight: bold;
    }

    &__api-message {
        border: solid 1px #ceefc3;
        background-color: #eff9ec;
        padding: 10px;

        img {
            width: 90%;
            height: 90%;

            @media (max-width: 780px) {
                width: 70px;
                height: 70%;
            }
        }

        span {
            font-size: 14px;
            font-weight: 300 !important;
        }
    }

    &__contact-sec {
        h3 {
            font-size: 1.6em;
            margin: 30px 0 15px 0;
        }

        li {
            list-style: none;
            line-height: 25px !important;
        }

        Link {
            padding: 14px;
        }

        span {
            margin: 10px 0;
            font-weight: 300;
        }
    }
}
.payment-modal {
    z-index: 999 !important;
}
// ..... MODAL ..... \\
.contact-modal {
    font-family: "Poppins", sans-serif;
    max-width: 50% !important;
    margin-top: 6% !important;
    height: auto !important;
    overflow-x: auto;

    @media (max-width: 768px) {
        max-width: 90% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        margin-top: 12% !important;
    }

    @media (max-width: 1092.99px) and (max-width: 768.99px) {
        max-width: 90% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    &__head {
        h4 {
            font-weight: 700 !important;
            font-size: 17.5px !important;
            line-height: 20px !important;
            margin: 30px 0 15px 0 !important;

            @media (max-width: 420px) {
                font-weight: 700 !important;
                font-size: 14.5px !important;
            }
        }
    }

    &__modal-scrollable {
        //max-height: 250px;
        overflow-y: auto;

        @media (max-width: 420px) {
            max-height: 200px;
            overflow-y: auto;
        }
    }

    &__modal-label {
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }

    &__modal-input {
        border: 1px solid #ccc;
        transition: border-color 0.3s ease;
        // &:focus{
        //     border : 1px solid #D4FF00 !important;
        //     box-shadow: none !important;
        // }
    }

    &__Update-btn {
        background-color: #00bb20 !important;
        color: #fff !important;
        font-weight: 400 !important;
        // line-height: 18px !important;
        border: none !important;
    }

    &__password-para {
        font-size: 1em;
        letter-spacing: -0.3px;
        margin-bottom: 30px;
        line-height: 1.6rem;
        margin: 20px 0;
    }

    &__payment-card {
        padding: 10px 0px;
    }

    &__payment-card {
        width: 22% !important;
    }
}

//Cancel MemberShip //
.cancelMembership {
    font-family: "Poppins", sans-serif;

    h1 {
        letter-spacing: -1px;
        margin: 15px 0;
        font-size: 2.4em;
        line-height: 1em;
        font-weight: bold;

        @media (max-width: 480px) {
            font-size: 2em;
            margin: 10px 0;
        }
    }

    p {
        line-height: 1.6rem;
        margin: 20px 0;
        font-size: 1rem;

        @media (max-width: 480px) {
            line-height: 1.6rem !important;
            font-size: 0.9em;
            margin: 7px 0;
        }
    }

    &__strong-text {
        font-weight: 700;
    }

    &__warning-message {
        border: solid 1px #ceefc3;
        background-color: #eff9ec;
        padding: 10px;

        img {
            width: 40px !important;
            height: 40px !important;

            @media (max-width: 780px) {
                width: 70px;
                height: 70%;
            }
        }
    }

    &__dive-deeper-text {
        line-height: 1.6rem;
        margin: 20px 0;
        font-size: 1rem;

        @media (max-width: 480px) {
            line-height: 1.3rem !important;
            font-size: 1rem;
            margin: 14px 0;
        }
    }

    &__btn-member {
        font-size: 16.8px;
        line-height: 18.2px;

        @media (max-width: 480px) {
            font-size: 10.8px;
            line-height: 10.2px;
        }

        &__btn {
            padding: 18px 36px 18px 36px !important;

            @media (max-width: 480px) {
                margin: 4px 0px;
                padding: 10px 24px 10px 24px !important;
                font-size: 13px !important;
            }

            @media (max-width: 1201.99px) {
                margin: 4px 0px;
                //     padding: 10px 24px 10px 24px !important;
                //    font-size: 13px !important;
            }
        }
    }
}

//Main-Footer//
.main-footer {
    color: #fff;
    gap: 80px;
    background-color: #121a20;
    font-family: "Inter", sans-serif;
    padding-top: 30px;
    padding-bottom: 30px;

    h4 {
        font-family: Archivo;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 1.149999976158142px;
    }

    img {
        width: 70%;
        @media (max-width: 767.98px) {
            width: 180px;
            margin: auto;
            display: flex;
        }
        @media (min-width: 780px) and (max-width: 1040px) {
            // width: 180px;
        }
    }

    p {
        margin-bottom: 4px !important;
        font-family: Archivo;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.75px;
        text-align: start;

        @media (max-width: 575.98px) {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            padding: 5px 0;
        }
    }

    span {
        font-family: Archivo;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.75px;
        text-align: center;
    }

    &__text {
        @media (max-width: 575.98px) {
            justify-content: center;
            align-items: center;
        }
    }

    &__set-text {
        @media (max-width: 575.98px) {
            text-align: center !important;
            align-items: center;
            justify-content: center;
            margin: auto !important;
            padding: 5px 0;
        }
    }

    &__text-small {
        @media (max-width: 575.98px) {
            text-align: center !important;
            align-items: center;
            justify-content: center;
            margin: auto !important;
            padding: 5px 0;
        }
    }
}

// .input{
//     position: relative;
//   }

//   .input:after {
//     content: "";
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 1px;
//     height: 70%;
//     background-color: #ccc;
//   }
