@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;700;800&display=swap");

.main-bg {
    background: url(../img/full-bg.jpg);
    min-height: 100vh;
    background-position: center 65%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed !important;
}

.signIn__input-check .mx-2,
.signIn__input-check .signIn__input-password-help {
    top: -5px !important;
    position: relative !important;
}

.signIn {
    p,
    a {
        @media (max-width: 320px) {
            font-size: 11px !important;
            letter-spacing: 0.2 !important;
            line-height: 2;
        }

        @media (max-width: 340px) and (min-width: 320px) {
            font-size: 12px !important;
            line-height: 1.9;
            letter-spacing: 0.2 !important;
        }

        @media (max-width: 420px) and (min-width: 341px) {
            font-size: 13px !important;
            letter-spacing: 0.2 !important;
            line-height: 1.8;
        }
    }

    &__input-password-help {
        color: #00bb20 !important;
    }

    &__main {
        margin-top: 10rem !important;

        @media (max-width: 768px) {
            margin-top: 6rem !important;
        }
    }

    &__input-check input[type="checkbox"] {
        p,
        a {
        }

        @media (max-width: 340px) {
            transform: scale(0.8);
        }

        @media (max-width: 420px) and (min-width: 341px) {
            transform: scale(0.9);
        }
    }

    &__input-check {
        font-size: 13px !important;
        letter-spacing: -0.8px;
        font-weight: 400;
    }

    h2 {
        font-weight: 700 !important;
        font-family: "Poppins", sans-serif;
    }

    &__body {
        font-family: "Poppins", sans-serif;
        padding: 2rem;
    }

    &__input {
        border: 1px solid #f7f7f7 !important;
        border-radius: 0 !important;
        background-color: #f7f7f7 !important;

        &:focus {
            border: 1px solid #d4ff00 !important;
            box-shadow: 0px 1px 3px rgba(143, 255, 54, 0.4) !important;
        }
    }

    &__label {
        font-weight: 700;
        line-height: 20px;
        font-size: 14px;
    }

    ::placeholder {
        font-size: 14px;

        @media (max-width: 340px) {
            font-size: 12px;
        }
    }

    .form-control:focus,
    .form-control:active {
        box-shadow: 2px solid rgb(106, 199, 121) !important;
        border-color: 2px solid rgb(106, 199, 121) !important;
    }

    &__btn {
        font-weight: 500 !important;
        border: none !important;
        color: #5cc33d !important;
        cursor: pointer;

        @media (max-width: 699.9px) and (min-width: 300px) {
            width: 95%;
        }
    }

    .maincard {
        padding-left: 0;
        padding-right: 0;
    }
}
.password_fied {
    position: relative;

    .append {
        position: absolute;
        z-index: 10;
        right: 20px;
        top: 10px;
    }
}
.signIn__btn:hover {
    cursor: pointer;
    background: #f3f4ff !important;
    color: #5cbc3f !important;
}

@media (max-width: 768px) {
    .signIn {
        &__body {
            padding: 1rem;
        }
    }
}

@media (max-width: 576px) {
    .signIn {
        &__body {
            padding: 0.5rem;
        }

        h2 {
            font-size: 1.5rem !important;
        }
    }
}

//forget-password page
.forget {
    &__main {
        margin-top: 10rem !important;

        @media (max-width: 768px) {
            margin-top: 6rem !important;
        }
    }

    h1 {
        font-weight: bold !important;
        font-family: "Poppins", sans-serif;
        line-height: 1em;
        font-size: 2rem;
    }

    p {
        line-height: 1.6rem;
        margin: 20px 0;
        font-size: 1rem;
        color: #666 !important;
    }

    &__btn {
        font-weight: 500 !important;
        border: none !important;
        color: #5cc33d !important;
        padding: 15px 30px;
        cursor: pointer;
    }

    &__body {
        font-family: "Poppins", sans-serif;
        padding: 2rem;
    }

    @media (max-width: 768px) {
        &__body {
            padding: 1rem;
        }
    }

    @media (max-width: 576px) {
        &__body {
            padding: 0.5rem;
        }

        h2 {
            font-size: 1.5rem !important;
        }
    }
}

.short-sec {
    font-family: "Poppins", sans-serif !important;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8) !important;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;

    h4, h5 {
        font-size: 14px;
        font-weight: 300;
        margin: 0;
    }

    a {
        color: #70ed69 !important;
        text-decoration: none;
    }

    span {
        font-weight: 700 !important;

        @media (max-width: 420px) and (min-width: 320px) {
            font-size: 14.5px !important;
        }
    }
}
