.example {
    &__api-tutorail {
        border: solid 1px #ceefc3;
        background-color: #eff9ec;
    }
}
.example-code {
    &__image-block {
        height: 400px !important;
        width: 100%;
    }
    &__code-block {
        height: 400px;
        @media screen and (max-width: 776.98px) {
            height: 300px;
        }
    }
}
