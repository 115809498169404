.api-navbar-style {
    // font-family: "Nunito", sans-serif;
    padding-left: 3%;
    padding-right: 3%;
    position: sticky !important;
    top: 80px !important;
    width: 100%;
    height: 80px;
    background-color: white;
    z-index: 9;

    @media (max-width: 1199.98px) {
        display: none !important;
    }
    .navbar-collapse {
        @media (max-width: 990px) {
            margin-top: -100px;
            background-color: #ffffff26;
        }
    }
    .navbar-nav {
        margin: auto;
    }
    .nav-item {
        margin: auto;
        min-width: fit-content;

        .navlink {
            padding: 10px 15px;

            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            color: #00bb20;

            &.active-link {
                color: #00bb20;
                // font-weight: 600;

                border-bottom: 2px solid #00bb20;
            }

            &:hover {
                border-bottom: 2px solid #eeee;
                text-decoration: none;
            }
        }
    }
    .nav-btn {
        min-width: min-content;
        font-size: 1rem;
        font-weight: 600;
        padding: 5px 25px;
        margin: 0px 10px;
        border: 1px solid #00bb20;
        color: #00bb20;
        &:hover {
            border: 1px solid #00bb20;
            color: #00bb20;
        }
    }
}
// .plan-card {
//     .title {
//         background-color: #0a1d18;
//         padding: 15px 25px;
//         border-radius: 8px 8px 0 0;
//         color: #fff;
//         font-weight: 400;
//         font-size: 1.2em;
//     }
//     .subTitle {
//         padding: 0px 0px;
//         font-weight: 400;
//         font-size: 14px;
//         font-family: "Poppins", sans-serif;
//     }
//     .cardBtn {
//         color: #fff;
//         font-size: 16px;
//         font-weight: 500;
//         border: none;
//         background-color: #00bb20 !important;
//         &:hover {
//             background-color: #00bb20;

//             color: #fff;

//             border: none;
//         }
//     }
// }

.plan-card2 {
    //border: 4px solid #00bb20 !important;
    border-radius: 3px !important;

    .title {
        background-color: #0a1d18;
        padding: 15px 25px;
        border-radius: 3px 3px 0 0;
        color: #fff;
        font-weight: 400;
        font-size: 1.2em;
    }
    .subTitle {
        padding: 10px 10px;
        font-weight: 400;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
    }
    .cardBtn {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border: none;
        background-color: #00bb20 !important;
        &:hover {
            background-color: #00bb20;

            color: #fff;

            border: none;
        }
    }
}
.btn-enterprise {
    background-color: #f3f4ff !important;
    padding: 18px 36px;
    color: #00bb20 !important;

    border: 1px solid #00bb20 !important;
    &:hover {
        color: #00bb20;

        border: 1px solid #00bb20;
    }
    &:active {
        color: #00bb20;

        border: 1px solid #00bb20;
    }
}
.enterprise-img {
    width: 120px;
    height: 180px;
}
.golf-course {
    border-radius: 12px;
    &__card {
        // border-radius: 0.3em 0.3em 0 0;
        &__header {
            //background-color: #eff5f8;
            border-bottom: 2px solid #ccc;
        }
        &__body {
            background-color: #fff;
        }
    }
}

.custom-input-group {
    position: relative;
}

.custom-input-group .image-addon {
    position: absolute;
    right: 10px; /* Adjust the positioning as needed */
    top: 50%; /* Adjust the positioning as needed */
    transform: translateY(-50%);
}

// .custom-input {
//     padding-right: 40px;
//     border: 1px solid rgb(106, 199, 121) !important;
// }

.cardBtnGolf {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border: none !important;
    background-color: #00bb20 !important;
    &:hover {
        background-color: #00bb20;

        color: #fff;

        border: none;
    }
}
.coupon-btn {
    color: #66778a !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border: none !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
    &:hover {
        color: #66778a !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        border: none !important;
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}

.stripe-expiry {
    border: #ccc 1px solid;
}
.cvc-input {
    border-left: 1px solid rgb(106, 199, 121) !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
    ::placeholder {
        font-size: 14px;

        @media (max-width: 340px) {
            font-size: 12px;
        }
    }
}

.select-input {
    background-color: #f2f7f9 !important;
    border: none !important;
    outline: none !important;
    border-radius: 50px !important;
    color: #00bb20 !important;
}

.select-input:focus {
    border-color: none !important; /* Desired focused border color */
    box-shadow: none !important; /* Desired focused box shadow */
}

.footer-icon {
    padding: 5px;

    border-radius: 50%;
    background-color: #f7f7f7;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar_button {
    background-color: transparent !important;
    border: none !important;
    &:focus {
        box-shadow: none !important;
    }
}
